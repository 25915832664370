<template>
  <div>
    <h2 v-if="subscriptionData.active_subscription !== null">
      Aktiivsed tellimused
    </h2>

    <div
      class="flex flex-row w-full -mx-3 mobile:mx-0 mobile:flex-col"
      v-if="subscriptionData.active_subscription !== null"
    >
      <subscription-card
        v-for="subscription in subscriptionData.active_subscription"
        :key="subscription.uuid"
        :subscription="subscription"
        :subscription-tiers="subscriptionTiers"
        :subscription-extras="subscriptionExtras"
        :subscription-types="subscriptionTypes"
        @viewInvoice="viewInvoice"
      ></subscription-card>
    </div>
    <div
      v-if="
        !subscriptionData.active_subscription ||
          subscriptionData.active_subscription.length === 0
      "
    >
      Aktiivsed tellimused puuduvad!
    </div>
    <h2 v-if="subscriptionData.subscription.length > 0">
      Tulevased tellimused
    </h2>
    <div
      class="flex flex-row flex-wrap -mx-3 mobile:mx-0 mobile:flex-col"
      v-if="subscriptionData.subscription.length > 0"
    >
      <subscription-card
        v-for="subscription in subscriptionData.subscription"
        :key="subscription.uuid"
        :subscription="subscription"
        :subscription-tiers="subscriptionTiers"
        :subscription-extras="subscriptionExtras"
        :subscription-types="subscriptionTypes"
        @viewInvoice="viewInvoice"
      ></subscription-card>
    </div>
  </div>
</template>

<script>
import SubscriptionCard from "@/components/company/subscription/SubscriptionCard";
import moment from "moment";
export default {
  name: "SubscriptionOverview",
  props: {
    subscriptionData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    subscriptionTiers: {
      type: Array,
      default: () => {
        return [];
      }
    },
    subscriptionTypes: {
      type: Array,
      default: () => {
        return [];
      }
    },
    subscriptionExtras: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: { SubscriptionCard },
  methods: {
    viewInvoice(e) {
      this.$emit("viewInvoice", e);
    }
  },
  computed: {
    parseStartDate() {
      return moment(this.subscriptionData.start_date).format(
        "MMMM Do YYYY, HH:mm:ss"
      );
    },
    parseEndDate() {
      return moment(this.subscriptionData.end_date).format(
        "MMMM Do YYYY, HH:mm:ss"
      );
    }
  }
};
</script>
