var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.subscriptionsLoaded)?_c('div',{staticClass:"flex flex-col mx-auto bg-white rounded-gwdhalf"},[_c('div',{staticClass:"flex flex-col justify-between"},[_c('div',{staticClass:"flex w-full mobile:flex-col"},[_c('div',{staticClass:"pb-3 flex flex-col w-3/4 pr-4 mobile:pr-0 mobile:w-full mobile:items-center"},[_c('h2',{staticClass:"mb-4"},[_vm._v("Aktiivne pakett")]),_c('div',{staticClass:"flex flex-col mobile:items-center"},[(
              _vm.subscriptionData.active_subscription &&
                _vm.subscriptionData.active_subscription.length > 0
            )?_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"flex font-medium"},[_c('h4',[_vm._v(" "+_vm._s(_vm.subscriptionData.active_subscription[0].subscription_tier .name)+" "),(_vm.subscriptionData.active_subscription[0].trial_period)?_c('span',[_vm._v("Prooviperiood")]):_vm._e(),_vm._v(" | "+_vm._s(_vm.subscriptionData.active_subscription[0].trial_period ? "0" : _vm.subscriptionData.active_subscription[0].total_price)+"€/kuu ")])]),_c('div',{staticClass:"flex flex-col mb-4"},[_c('span',[_vm._v("Paketi algus: "+_vm._s(_vm.moment( _vm.subscriptionData.active_subscription[0].start_date ).format("DD.MM.YYYY")))]),_c('span',[_vm._v("Paketi lõpp: "+_vm._s(_vm.moment( _vm.subscriptionData.active_subscription[0].end_date ).format("DD.MM.YYYY")))])]),_c('div',{staticClass:"flex mobile:w-full"},[_c('router-link',{staticClass:"text-white self-center rounded-full mobile:w-full",attrs:{"to":"/subscriptions/add"}},[_c('button',{staticClass:"new-button-green mobile:w-full"},[_c('div',{staticClass:"h-8 w-8 rounded-full p-1 icon"},[_c('img',{staticClass:"filter-to-white",attrs:{"src":"/icons/logonohighlight.png"}})]),_c('span',{staticClass:"label"},[_vm._v("Muuda paketti")])])])],1)]):_vm._e(),(
              (_vm.subscriptionData.active_subscription &&
                _vm.subscriptionData.active_subscription.length === 0) ||
                !_vm.subscriptionData.active_subscription
            )?_c('div',{staticClass:"flex flex-col"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"flex"},[_c('router-link',{staticClass:"text-white self-center rounded-full",attrs:{"to":"/subscriptions/add"}},[_c('button',{staticClass:"new-button-green"},[_c('div',{staticClass:"icon"},[_c('img',{staticClass:"filter-to-white",attrs:{"src":"/icons/logonohighlight.png"}})]),_c('span',{staticClass:"label"},[_vm._v("Muuda paketti")])])])],1)]):_vm._e(),(
              !_vm.$store.state.companyData.activeCompany.trial_period_used &&
                _vm.$store.state.companyData.activeCompany.worker_permission ===
                  'R0'
            )?_c('div',{staticClass:"mt-4 flex-col flex mb-4 mobile:items-center"},[_c('span',{staticClass:"text-xl font-medium mb-2"},[_vm._v("Soovid Gewodo Premiumi proovida?")]),_c('router-link',{attrs:{"to":"/trial"}},[_c('button',{staticClass:"new-button-green"},[_c('span',{staticClass:"typcn typcn-tick icon"}),_c('span',{staticClass:"label"},[_vm._v("Aktiveeri prooviperiood")])])])],1):_vm._e()]),_c('div',{staticClass:"w-full h-1 bg-offwhite rounded-full mb-4"}),_c('div',{},[(_vm.subscriptionsLoaded)?_c('subscription-overview',{attrs:{"subscription-data":_vm.subscriptionData,"subscription-tiers":_vm.subscriptionTiers,"subscription-extras":_vm.subscriptionExtras,"subscription-types":_vm.subscriptionTypes},on:{"viewInvoice":_vm.viewInvoice}}):_vm._e()],1)]),_vm._m(2)])]),(_vm.pdfViewActive && _vm.pdfSrc)?_c('div',[_c('pdf-modal',{attrs:{"pdf-src":_vm.pdfSrc},on:{"closeModal":function($event){_vm.pdfViewActive = false}}})],1):_vm._e()]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex font-medium mb-4"},[_c('span',[_vm._v("Baas | - | 0€/kuu")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col mb-4"},[_c('span',[_vm._v("Paketi algus: -")]),_c('span',[_vm._v("Paketi lõpp: -")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-1/4 mobile:w-full hidden"},[_c('div',{staticClass:"flex flex-col bg-offwhite rounded-xl w-full p-3 shadow"},[_c('span',{staticClass:"font-semibold text-lg"},[_vm._v("Arved")])])])}]

export { render, staticRenderFns }