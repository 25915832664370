<template>
  <div class="flex flex-col w-1/4 medium:w-1/3 mobile:w-full p-3">
    <div class="bg-offwhite rounded shadow p-3">
      <div class="flex flex-col w-full mb-2">
        <h4>Teenus</h4>
        <span> Pakett {{ subscription.subscription_tier.name }} </span>
      </div>
      <div class="flex flex-col mb-2">
        <h4>Teenuse algus</h4>
        <span>{{ parseStartDate }}</span>
      </div>
      <div class="flex flex-col mb-2">
        <h4>Teenuse lõpp</h4>
        <span>{{ parseEndDate }}</span>
      </div>
      <div>
        <div
          class="flex flex-row w-full items-start justify-start mb-2"
          v-if="subscription.paid"
        >
          <button
            v-if="subscription.has_invoice"
            @click="downloadPDF"
            class="new-button-pending"
          >
            <span class="icon typcn typcn-document-text"></span>
            <span class="label">Vaata arvet</span>
          </button>
        </div>
        <div v-if="!subscription.paid">
          <button @click="payForSubscription()" class="new-button-green">
            Maksa tellimuse eest
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import RequestHandler from "@/assets/mixins/RequestHandler";

export default {
  name: "SubscriptionCard",
  components: {},
  mixins: [RequestHandler],
  props: {
    subscription: {
      type: Object,
      default: () => {
        return {};
      }
    },
    subscriptionTiers: {
      type: Array,
      default: () => {
        return [];
      }
    },
    subscriptionTypes: {
      type: Array,
      default: () => {
        return [];
      }
    },
    subscriptionExtras: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  methods: {
    parseState(subscription) {
      switch (subscription.state) {
        case 1:
          return "Lõppenud";
        case 2:
          return "Aktiivne";
        case 3:
          return "Algamata";
        default:
          return "WTF";
      }
    },
    downloadPDF() {
      this.apiRequest(
        "files/private/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/subscription/" +
          this.subscription.uuid,
        "get",
        true
      ).then(res => {
        this.$emit("viewInvoice", res.data.file_url);
        //
        // const url = res.data.file_url;
        // const link = document.createElement("a");
        // link.href = url;
        // link.target = "_blank";
        // link.setAttribute("download", "arve.pdf");
        // document.body.appendChild(link);
        // link.click();
      });
    },
    payForSubscription() {
      let subscriptionData = {
        company_id: this.$store.state.companyData.activeCompany.uuid,
        subscription_id: this.subscription.uuid
      };
      this.apiRequest(
        "subscription/individual/new/",
        "post",
        true,
        subscriptionData
      ).then(res => {
        if (res.status === 201) {
          window.location.href =
            process.env.VUE_APP_MONTONIO_URL + res.data.payment_token;
        }
        this.$store.state.loading = false;
      });
    }
  },
  computed: {
    parseStartDate() {
      return moment(this.subscription.start_date).format("DD.MM.YYYY");
    },
    parseEndDate() {
      return moment(this.subscription.end_date).format("DD.MM.YYYY");
    }
  }
};
</script>
