<template>
  <div
    v-if="subscriptionsLoaded"
    class="flex flex-col mx-auto bg-white rounded-gwdhalf"
  >
    <div class="flex flex-col justify-between">
      <div class="flex w-full mobile:flex-col">
        <div
          class="pb-3 flex flex-col w-3/4 pr-4 mobile:pr-0 mobile:w-full mobile:items-center"
        >
          <h2 class="mb-4">Aktiivne pakett</h2>
          <div class="flex flex-col mobile:items-center">
            <div
              v-if="
                subscriptionData.active_subscription &&
                  subscriptionData.active_subscription.length > 0
              "
              class="mb-4"
            >
              <div class="flex font-medium">
                <h4>
                  {{
                    subscriptionData.active_subscription[0].subscription_tier
                      .name
                  }}
                  <span
                    v-if="subscriptionData.active_subscription[0].trial_period"
                    >Prooviperiood</span
                  >
                  |
                  {{
                    subscriptionData.active_subscription[0].trial_period
                      ? "0"
                      : subscriptionData.active_subscription[0].total_price
                  }}€/kuu
                </h4>
              </div>
              <div class="flex flex-col mb-4">
                <span
                  >Paketi algus:
                  {{
                    moment(
                      subscriptionData.active_subscription[0].start_date
                    ).format("DD.MM.YYYY")
                  }}</span
                >
                <span
                  >Paketi lõpp:
                  {{
                    moment(
                      subscriptionData.active_subscription[0].end_date
                    ).format("DD.MM.YYYY")
                  }}</span
                >
              </div>
              <div class="flex mobile:w-full">
                <router-link
                  to="/subscriptions/add"
                  class="text-white self-center rounded-full mobile:w-full"
                >
                  <button class="new-button-green mobile:w-full">
                    <div class="h-8 w-8 rounded-full p-1 icon">
                      <img
                        src="/icons/logonohighlight.png"
                        class="filter-to-white"
                      />
                    </div>
                    <span class="label">Muuda paketti</span>
                  </button>
                </router-link>
              </div>
            </div>
            <div
              v-if="
                (subscriptionData.active_subscription &&
                  subscriptionData.active_subscription.length === 0) ||
                  !subscriptionData.active_subscription
              "
              class="flex flex-col"
            >
              <div class="flex font-medium mb-4">
                <span>Baas | - | 0€/kuu</span>
              </div>
              <div class="flex flex-col mb-4">
                <span>Paketi algus: -</span>
                <span>Paketi lõpp: -</span>
              </div>
              <div class="flex">
                <router-link
                  to="/subscriptions/add"
                  class="text-white self-center rounded-full"
                >
                  <button class="new-button-green">
                    <div class="icon">
                      <img
                        src="/icons/logonohighlight.png"
                        class="filter-to-white"
                      />
                    </div>
                    <span class="label">Muuda paketti</span>
                  </button>
                </router-link>
              </div>
            </div>
            <div
              class="mt-4 flex-col flex mb-4 mobile:items-center"
              v-if="
                !$store.state.companyData.activeCompany.trial_period_used &&
                  $store.state.companyData.activeCompany.worker_permission ===
                    'R0'
              "
            >
              <span class="text-xl font-medium mb-2"
                >Soovid Gewodo Premiumi proovida?</span
              >
              <router-link to="/trial">
                <button class="new-button-green">
                  <span class="typcn typcn-tick icon"></span>
                  <span class="label">Aktiveeri prooviperiood</span>
                </button>
              </router-link>
            </div>
          </div>
          <div class="w-full h-1 bg-offwhite rounded-full mb-4"></div>
          <div class="">
            <subscription-overview
              v-if="subscriptionsLoaded"
              :subscription-data="subscriptionData"
              :subscription-tiers="subscriptionTiers"
              :subscription-extras="subscriptionExtras"
              :subscription-types="subscriptionTypes"
              @viewInvoice="viewInvoice"
            ></subscription-overview>
          </div>
        </div>
        <div class="w-1/4 mobile:w-full hidden">
          <div class="flex flex-col bg-offwhite rounded-xl w-full p-3 shadow">
            <span class="font-semibold text-lg">Arved</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="pdfViewActive && pdfSrc">
      <pdf-modal
        :pdf-src="pdfSrc"
        @closeModal="pdfViewActive = false"
      ></pdf-modal>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
const subscriptionTypes = [
  {
    id: 1,
    name: "Aastane tellimus, ühekordne makse",
    information_text:
      "Saad kasutajaõiguse aastaks, maksad ühe korra kogu aasta eest",
    price_multiplier: 12
  },
  {
    id: 2,
    name: "Aastane tellimus, kuumaksetega",
    information_text: "Saad kasutajaõiguse aastaks, maksad iga kuu kuutasu",
    price_multiplier: 1
  }
  // {
  //   id: 3,
  //   name: "Kuu tellimus",
  //   information_text:
  //     "Saad kasutajaõiguse üheks kuuks, võid aga ei pea tasuma järgmise kuu eest, kuutasu suureneb võrreldes aastase tellimusega",
  //   price_multiplier: 1
  // }
];
import SubscriptionOverview from "@/components/company/subscription/SubscriptionOverview";
import moment from "moment";
import PdfModal from "@/components/reusable/PdfModal";

export default {
  name: "Subscriptions",
  components: {
    PdfModal,
    SubscriptionOverview
  },
  mixins: [RequestHandler],
  data() {
    return {
      company: null,
      subscriptionData: null,
      subscriptionsLoaded: false,
      subscriptionTiers: [],
      subscriptionTypes: [],
      subscriptionExtras: [],
      moment: moment,
      pdfViewActive: false,
      pdfSrc: null
    };
  },
  methods: {
    viewInvoice(e) {
      this.pdfViewActive = true;
      this.pdfSrc = e;
    },
    retrieveCompanySubscriptions() {
      this.$store.state.loading = true;
      this.subscriptionsLoaded = false;

      this.apiRequest(
        "subscription/" + this.company.uuid + "/",
        "get",
        true
      ).then(res => {
        this.subscriptionData = res.data;
        this.subscriptionsLoaded = true;
        this.$store.state.loading = false;
      });
    },
    retrieveSubscriptionTiers() {
      this.apiRequest("subscription/tiers/", "get", true).then(res => {
        this.subscriptionTiers = res.data;
      });
    },
    retrieveSubscriptionExtras() {
      this.apiRequest("subscription/extras/", "get", true).then(res => {
        this.subscriptionExtras = res.data;
      });
    },
    loadModalRequirements() {
      this.$store.state.loading = true;
      this.subscriptionTypes = subscriptionTypes;
      this.retrieveSubscriptionTiers();
      this.retrieveSubscriptionExtras();
      this.retrieveCompanySubscriptions();

      this.$store.state.loading = false;
    }
  },
  mounted() {
    this.company = this.$store.state.companyData.activeCompany;

    this.loadModalRequirements();
  },
  computed: {
    parseStartDate() {
      if (this.subscriptionData.start_date) {
        return moment(this.subscriptionData.start_date).format(
          "MMMM Do YYYY, HH:mm:ss"
        );
      } else {
        return "-";
      }
    },
    parseEndDate() {
      if (this.subscriptionData.end_date) {
        return moment(this.subscriptionData.end_date).format(
          "MMMM Do YYYY, HH:mm:ss"
        );
      } else {
        return "-";
      }
    }
  }
};
</script>
